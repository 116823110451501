import React from "react";

const Features6 = () => {
  const illustrationFeature6 = require("../assets/images/profesionales.png");
  return (
    <section id="features6" className="mb-16 bg-[#f25f3a] p-7">
      <div className="container mx-auto px-4 mt-10 mb-11">
        <div className="flex flex-col md:flex-row items-center md:items-start space-y-12 md:space-y-0 md:space-x-8">
          {/* Image on the left */}
          <div className="w-full md:w-1/2">
            <img
              src={illustrationFeature6}
              alt="profesionales"
              className="w-full h-auto "
            />
          </div>

          {/* Text content on the right */}
          <div className="w-full md:w-1/2 flex flex-col space-y-8">
            <h2 className="text-4xl font-bold text-center md:text-left text-white">
              Portal de atención y agenda personalizada para cada profesional
            </h2>
            <p className="text-center md:text-left text-white">
              Proporciona a cada profesional un espacio exclusivo para gestionar
              sus citas y atender a sus clientes. Nuestro portal de atención
              facilita la organización de agendas, permitiendo que cada
              especialista tenga el control total sobre su disponibilidad y
              horarios. Mejora la eficiencia y la satisfacción del cliente con
              una gestión personalizada.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features6;
