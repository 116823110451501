import { Check } from "lucide-react";
import React from "react";

const Pricing = () => {
  return (
    <section id="pricing" className="bg-[#f25f3a] text-white py-16">
      <div className="container mx-auto px-4">
        <p className="text-center text-xl mb-8">Precio</p>
        <h2 className="text-center text-4xl font-bold mb-8">
          ESCOGE EL PLAN PERFECTO PARA TUS NECESIDADES
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 justify-items-center justify-center">
          {[
            {
              title: "Gold",
              description:
                "Plan perfecto para profesionales independientes que buscan un aliado para gestionar y hacer crecer su negocio. ¡Toma el control y empieza a crecer hoy!",
              price: "2.7 UF",
              features: [
                "Agenda online de citas ilimitadas",
                "Gestión de clientes",
                "Ficha clínica",
                "Recordatorios automáticos mediante correos",
                "Confirmación ilimitada mediante correo",
                "Página web autoadministrada pública para tus clientes",
                "Notificaciones y alertas diarias",
                "Permite pagos presencial y en línea",
                "Portal Administrador",
                "Portal Clientes",
              ],
            },
            {
              title: "Platinum",
              description:
                "Para negocios que desean optimizar su organización, mejorar su administración y aumentar sus ventas. ¡Impulsa tu crecimiento hoy mismo!",
              price: "7.9 UF",
              isPopular: true,
              features: [
                "Todas las funcionalidades del plan Gold, más:",
                "Sin límite de profesionales",
                "Portal Profesionales",
                "Tótem de pago presencial",
                "Correos ilimitados mensuales de marketing",
                "Facturación Electrónica",
              ],
            },
            {
              title: "Worldmember",
              description:
                "Para empresas que buscan tener el control y llevar un seguimiento de pacientes/clientes personalizable.",
              price: "15.8 UF",

              features: [
                "Todas las funcionalidades del plan gold y platinum, más:",
                "Confirmaciones ilimitadas de Whatsapp",
                "Soporte personalizado",
                "Posibilidad de solicitar requerimientos adicionales",
              ],
            },
          ].map((plan, index) => (
            <div
              key={index}
              className={`bg-white text-gray-800 rounded-lg shadow-lg p-8 flex flex-col ${
                plan.isPopular ? "border-4 border-purple-500" : ""
              } transform transition duration-300 hover:scale-105 hover:shadow-xl`}
            >
              {plan.isPopular && (
                <span className="bg-[#f25f3a] text-white text-xs font-bold px-3 py-1 rounded-full self-start mb-4">
                  Más popular
                </span>
              )}
              <h3 className="text-2xl font-bold mb-2">{plan.title}</h3>
              <p className="text-gray-600 mb-4">{plan.description}</p>
              <p className="text-3xl font-bold mb-1">${plan.price}</p>
              <p className="text-sm text-gray-500 mb-4">mensuales + IVA</p>
              <p className="font-bold mb-2">Incluye:</p>
              <ul className="space-y-2">
                {plan.features.map((feature, featureIndex) => (
                  <li key={featureIndex} className="flex items-start">
                    <Check className="w-5 h-5 text-[#f25f3a] mr-2 flex-shrink-0 mt-1" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Pricing;
