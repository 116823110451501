function Hero() {
  const illustrationIntro = require("../assets/images/home.jpg");
  return (
    <section id="hero" className="p-7">
      {/* Flex Container */}
      <div className="container flex flex-col-reverse items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0 md:flex-row">
        {/* Left Item */}
        <div className="flex flex-col mb-32 space-y-12 md:w-1/2">
          <h1 className="max-w-md text-4xl font-bold text-center md:text-5xl md:text-left">
            La Solución Integral para la Gestión de Tu Empresa
          </h1>
          <p className="max-w-sm text-center text-darkGrayishBlue md:text-left">
            Transforma la gestión de tu negocio con nuestra plataforma
            todo-en-uno. Automatiza tus agendas, envía recordatorios ilimitados
            y acepta múltiples medios de pago. Ofrece un portal de autogestión
            para que tus pacientes gestionen sus citas y optimiza transacciones
            con un tótem de pago. ¡Descubre un nuevo nivel de eficiencia!
          </p>
          <div className="flex justify-center md:justify-start">
            <a
              href="mailto:eduardo.valenzuela@codegennials.com"
              className="p-3 px-6 pt-2 text-white bg-brightRed rounded-full baseline hover:bg-brightRedLight"
            >
              Contáctanos
            </a>
          </div>
        </div>
        {/* Image */}
        <div className="md:w-1/2">
          <img src={illustrationIntro} alt="home" />
        </div>
      </div>
    </section>
  );
}

export default Hero;
