import React from "react";

const Features5 = () => {
  const illustrationFeature4 = require("../assets/images/booking_patient.png");
  return (
    <section id="features5" className="p-7">
      {/* Flex Container */}
      <div className="container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row mb-11">
        {/* What's Different */}
        <div className="flex flex-col space-y-12 md:w-1/2">
          <h2 className="max-w-md text-4xl font-bold text-left">
            Portal de autogestión para pacientes
          </h2>
          <p className="max-w-sm text-darkGrayishBlue text-left">
            Permite que tus pacientes gestionen sus citas de manera fácil y
            rápida a través de nuestro portal. Con un simple clic, pueden
            reservar, reprogramar o cancelar horas, brindándoles mayor control
            sobre su atención. Facilita la comunicación y mejora la experiencia
            del paciente, asegurando que cada visita sea conveniente y sin
            complicaciones.
          </p>
        </div>

        {/* Numbered List */}
        <div className="flex flex-col space-y-8 md:w-1/2">
          <img src={illustrationFeature4} alt="booking_patient" />
        </div>
      </div>
    </section>
  );
};

export default Features5;
