import React from "react";

const Features2 = () => {
  const illustrationFeature2 = require("../assets/images/agenda.png");
  return (
    <section id="features2" className="mb-16 bg-[#f25f3a] p-7">
      <div className="container mx-auto px-4 mt-10 mb-11">
        <div className="flex flex-col md:flex-row items-center md:items-start space-y-12 md:space-y-0 md:space-x-8">
          {/* Image on the left */}
          <div className="w-full md:w-1/2">
            <img
              src={illustrationFeature2}
              alt="agenda"
              className="w-full h-auto"
            />
          </div>

          {/* Text content on the right */}
          <div className="w-full md:w-1/2 flex flex-col space-y-8">
            <h2 className="text-4xl font-bold text-center md:text-left text-white">
              Control Total y Automatización de Agendas
            </h2>
            <p className="text-white text-center md:text-left">
              Simplifica la gestión de tus citas con nuestra herramienta
              intuitiva. Automatiza la programación de citas, evita conflictos
              de horarios y mantén tus agendas siempre organizadas. Dedica más
              tiempo a tu negocio y menos a la coordinación manual. ¡La agenda
              perfecta, sin esfuerzo!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features2;
