import React from "react";

const Features = () => {
  const illustrationFeature = require("../assets/images/correos_whatsapp.png");
  return (
    <section id="features" className="mb-16 p-7">
      {/* Flex Container */}
      <div className="container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row mb-11">
        {/* What's Different */}
        <div className="flex flex-col space-y-12 md:w-1/2">
          <h2 className="max-w-md text-4xl font-bold text-center md:text-left">
            Recordatorios ilimitados por Correo y WhatsApp
          </h2>
          <p className="max-w-sm text-center text-darkGrayishBlue md:text-left">
            Mantén a tus clientes siempre informados con recordatorios
            automáticos y personalizados. Envía notificaciones de citas por
            correo y WhatsApp, asegurando que nadie olvide su compromiso.
            ¡Olvídate de las ausencias y mantén la comunicación al día, sin
            límites!
          </p>
        </div>

        {/* Numbered List */}
        <div className="flex flex-col space-y-8 md:w-1/2">
          <img src={illustrationFeature} alt="correos_whatsapp" />
        </div>
      </div>
    </section>
  );
};

export default Features;
