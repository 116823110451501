import Features from "./Features/Features";
import Features2 from "./Features2/Features2";
import Features3 from "./Features3/Features3";
import Features4 from "./Features4/Features4";
import Features5 from "./Features5/Features5";
import Features6 from "./Features6/Features6";
import Features7 from "./Features7/Features7";
import Footer from "./Footer/Footer";
import Hero from "./Hero/Hero";
import Navbar from "./Navbar/Navbar";
import Pricing from "./Pricing/Pricing";

function App() {
  return (
    <>
      <Navbar />
      <Hero />
      <Features />
      <Features2 />
      <Features3 />
      <Features4 />
      <Features5 />
      <Features6 />
      <Features7 />
      <Pricing />
      <Footer />
    </>
  );
}

export default App;
