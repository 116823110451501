import React from "react";

const Features3 = () => {
  const illustrationFeature3 = require("../assets/images/sitio_web.png");
  return (
    <section id="features3" className="p-7">
      {/* Flex Container */}
      <div className="container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row mb-11">
        {/* What's Different */}
        <div className="flex flex-col space-y-12 md:w-1/2">
          <h2 className="max-w-md text-4xl font-bold text-center md:text-left">
            Sitio Web Responsivo y Autogestionado
          </h2>
          <p className="max-w-sm text-center text-darkGrayishBlue md:text-left">
            Ofrece a tus clientes una experiencia impecable en cualquier
            dispositivo. Nuestro sitio web responsivo se adapta a móviles,
            tablets y computadoras, brindándoles acceso rápido a tus servicios.
            Además, con nuestra plataforma autogestionada, podrás actualizar tu
            contenido fácilmente sin depender de terceros. ¡Controla tu
            presencia en línea de manera simple y eficaz!
          </p>
        </div>

        {/* Numbered List */}
        <div className="flex flex-col space-y-8 md:w-1/2">
          <img src={illustrationFeature3} alt="sitio_web" />
        </div>
      </div>
    </section>
  );
};

export default Features3;
