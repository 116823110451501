import React from "react";

const Features7 = () => {
  const illustrationFeature7 = require("../assets/images/totem.jpeg");
  return (
    <section id="features7" className="p-7">
      {/* Flex Container */}
      <div className="container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row mb-11">
        {/* What's Different */}
        <div className="flex flex-col space-y-12 md:w-1/2">
          <h2 className="max-w-md text-4xl font-bold text-left">
            Tótem de Pago: Comodidad en cada transacción
          </h2>
          <p className="max-w-sm text-darkGrayishBlue text-left">
            Integra un tótem de pago en tu establecimiento donde lo consideres
            más conveniente. Permite que tus clientes realicen pagos de manera
            rápida y segura, sin esperar en filas. Con opciones para pagos en
            efectivo, tarjeta o pagos móviles, el tótem mejora la experiencia
            del cliente y optimiza el flujo de trabajo en tu negocio. ¡Haz que
            pagar sea una experiencia sencilla y eficiente!
          </p>
        </div>

        {/* Numbered List */}
        <div className="flex flex-col space-y-8 md:w-1/2">
          <img src={illustrationFeature7} alt="totem" />
        </div>
      </div>
    </section>
  );
};

export default Features7;
