import { useState } from "react";

import companyLogo from "../assets/images/logo.png";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <nav className="relative container mx-auto">
      {/* Flex Container */}
      <div className="flex items-center justify-between">
        {/* Logo */}
        <div className="pt-2">
          <img src={companyLogo} alt="logo" width={200} />
        </div>
        {/* Menu Items */}
        <div className="hidden space-x-6 md:flex">
          <a href="#features" className="hover:text-darkGrayishBlue">
            Funcionalidades
          </a>
          <a href="#pricing" className="hover:text-darkGrayishBlue">
            Precios
          </a>
        </div>

        {/* Hamburger Icon */}
        <button
          className={
            toggleMenu
              ? "open block hamburger md:hidden focus:outline-none"
              : "block hamburger md:hidden focus:outline-none"
          }
          onClick={() => setToggleMenu(!toggleMenu)}
        >
          <span className="hamburger-top"></span>
          <span className="hamburger-middle"></span>
          <span className="hamburger-bottom"></span>
        </button>
      </div>

      {/* Mobile Menu */}
      <div className="md:hidden">
        <div
          className={
            toggleMenu
              ? "absolute flex flex-col items-center self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md"
              : "absolute flex-col items-center hidden self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md"
          }
        >
          <a href="#pricing" className="hover:text-darkGrayishBlue">
            Precios
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
