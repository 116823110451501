import React from "react";

const Features4 = () => {
  const illustrationFeature4 = require("../assets/images/metodo_pago.jpeg");
  return (
    <section id="features4" className="mb-16 bg-[#f25f3a] p-7">
      <div className="container mx-auto px-4 mt-10 mb-11">
        <div className="flex flex-col md:flex-row items-center md:items-start space-y-12 md:space-y-0 md:space-x-8">
          {/* Image on the left */}
          <div className="w-full md:w-1/2">
            <img
              src={illustrationFeature4}
              alt="agenda"
              className="w-full h-auto"
            />
          </div>

          {/* Text content on the right */}
          <div className="w-full md:w-1/2 flex flex-col space-y-8">
            <h2 className="text-4xl font-bold text-center md:text-left text-white">
              Diferentes opciones de pago para tus clientes
            </h2>
            <p className="text-white text-center md:text-left">
              Facilita el proceso de pago con múltiples opciones. Nuestra
              poderosa terminal de pagos y las soluciones de pagos en línea
              permiten que tus clientes o pacientes elijan la forma de pago que
              más les convenga. Acepta tarjetas, transferencias o pagos en
              efectivo, todo de manera rápida y segura. ¡Haz que pagar sea más
              fácil que nunca!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features4;
